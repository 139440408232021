import { createVNode, render } from 'vue'

import ToastConstructor from './toast.vue'
import { instances } from './instances'
import type { ToastOption } from './toast'

let seed = 0

const closeMessage = (instance: any) => {
  const idx = instances.indexOf(instance)
  if (idx === -1) return

  instances.splice(idx, 1)

  const { handler } = instance

  handler.close()
}

const createToast = (options: any) => {
  const id = 'toast_' + seed++

  const container = document.createElement('div')

  const props = {
    ...options,
    id,
    onClose: () => {
      closeMessage(instance)
    },
    onDestroy: () => {
      render(null, container)
    },
  }

  const vnode = createVNode(ToastConstructor, props, {
    default: () => options.message,
  })

  render(vnode, container)

  document.body.appendChild(container.firstElementChild!)

  const vm = vnode.component!

  const handler = {
    close: () => {
      vm.exposed!.visible.value = false
    },
  }

  const instance = {
    id,
    vnode,
    vm,
    handler,
    props: (vnode.component as any).props,
  }

  return instance
}

const toast = (options: ToastOption = {}) => {
  const instance = createToast(options)

  instances.push(instance)

  return instance
}

export default toast
