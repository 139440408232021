import type { Mutable } from '@type/typescript'

export const toastTypes = ['general', 'success', 'error'] as const
export type toastType = (typeof toastTypes)[number]

const toastDefaults = {
  id: '',
  type: 'general',
  message: '',
  duration: 3000,
  offset: 16,
  showClose: false,
  onClose: undefined,
} as const

export const toastProps = {
  id: {
    type: String,
    default: toastDefaults.id,
  },
  type: {
    type: String,
    values: toastTypes,
    default: toastDefaults.type,
  },
  message: {
    type: String,
    default: toastDefaults.message,
  },
  duration: {
    type: Number,
    default: toastDefaults.duration,
  },
  offset: {
    type: Number,
    default: toastDefaults.offset,
  },
  showClose: {
    type: Boolean,
    default: toastDefaults.showClose,
  },
  onClose: {
    type: Function as PropType<() => void>,
    default: toastDefaults.onClose,
  },
} as const

export type ToastProps = ExtractPropTypes<typeof toastProps>
export type ToastOption = Partial<
  Mutable<
    Omit<ToastProps, 'id'> & {
      appendTo?: HTMLElement | string
    }
  >
>

export const toastEmits = {
  destroy: () => true,
}
export type ToastEmits = typeof toastEmits
