export const instances = shallowReactive<any[]>([])

export const getInstance = (id: string) => {
  const idx = instances.findIndex((instance) => instance.id === id)
  const current = instances[idx]

  let prev: any | undefined

  if (idx > 0) {
    prev = instances[idx - 1]
  }

  return { current, prev }
}

export const getIndex = (id: string): number => {
  const idx = instances.findIndex((instance) => instance.id === id)

  return idx
}

export const getLastOffset = (id: string): number => {
  const { prev } = getInstance(id)

  if (!prev) return 0

  return prev.vm.exposed!.bottom.value
}

export const getOffsetOrSpace = (id: string, offset: number) => {
  const idx = instances.findIndex((instance) => instance.id === id)

  return idx > 0 ? 16 : offset
}
