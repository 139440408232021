<script lang="ts" setup>
import IconClose from '@components/ui/icons/close.vue'

import { toastProps, toastEmits } from './toast'
import { getIndex, getLastOffset, getOffsetOrSpace } from './instances'

const props = defineProps(toastProps)
defineEmits(toastEmits)

const { id, type, message, showClose } = toRefs(props)

const toastRef = ref<HTMLDivElement>()
const visible = ref(false)
const height = ref(0)

let stopTimer: (() => void) | undefined = undefined

const currentIndex = computed(() => getIndex(id.value))
const lastOffset = computed(() => getLastOffset(id.value))

const offset = computed(
  () => getOffsetOrSpace(id.value, props.offset) + lastOffset.value
)
const bottom = computed((): number => height.value + offset.value)
const customStyle = computed(() => ({
  top: `${offset.value}px`,
  zIndex: '200' + currentIndex.value,
}))

function startTimer() {
  if (props.duration === 0) return
  ;({ stop: stopTimer } = useTimeoutFn(() => {
    close()
  }, props.duration))
}

function clearTimer() {
  stopTimer?.()
}

function close() {
  visible.value = false
}

useResizeObserver(toastRef, () => {
  height.value = toastRef.value!.getBoundingClientRect().height
})

onMounted(() => {
  startTimer()

  visible.value = true
})

defineExpose({
  visible,
  bottom,
  close,
})
</script>

<template>
  <transition
    name="slide-fade"
    @before-leave="onClose"
    @after-leave="$emit('destroy')"
  >
    <div
      v-show="visible"
      ref="toastRef"
      :id="id"
      class="toast"
      :class="[`toast-${type}`]"
      :style="customStyle"
      @mouseenter="clearTimer"
      @mouseleave="startTimer"
    >
      <slot>
        <p>{{ message }}</p>
      </slot>

      <div class="toast-close" v-if="showClose" @click.stop="close">
        <IconClose />
      </div>
    </div>
  </transition>
</template>

<style lang="scss">
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);

  opacity: 0;
}

.toast {
  position: fixed;
  right: 24px;

  display: flex;
  align-items: center;

  width: fit-content;
  max-width: 100%;
  padding: 15px 20px;

  color: var(--c-grayscale-off-white);
  border-radius: 16px;

  gap: 10px;
  @include t-desktop-link-small;
}

.toast-general {
  background: var(--c-primary-default);
}

.toast-success {
  background: var(--c-success-default);
}

.toast-error {
  background: var(--c-error-default);
}

.toast-close {
  width: 24px;

  cursor: pointer;
}

</style>
